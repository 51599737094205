<template>
  <div class="earning">
    <div class="container">
      <div class="position-relative">
        <h1>Earning</h1>
        <!-- <p class="expectedEarning">Expected earnings: $28</p> -->
      </div>
      <!-- earning-summary -->
      <earning-summary />

      <div class="filtersOption">
        <div class="filtersOption__paymentOptions">
          <p>Withdraw:</p>
          <ul>
            <li>
              <a href="javascript:void(0);" v-b-modal.bankTransfer
                >Bank Tranfer</a
              >
            </li>
          </ul>
        </div>
        <div class="filtersOption__showsFilters">
          <billing-filters :filter-types="filterTypes" @filter="filters" />
        </div>
      </div>
      <!-- billing card -->

      <div v-if="type == 'transactions'">
        <transactions :filter-date="this.filterDate"></transactions>
      </div>
      <div v-if="type == 'withdraws'">
        <withdraws :filter-date="this.filterDate"></withdraws>
      </div>

      <!-- modal1 -->

      <b-modal content-class="accountModal " id="paypalAccount">
        <div class="title">Configurure Your Paypal Account</div>
        <p>
          We will use this PayPal account to send you money when you initiate a
          withdrawal.
        </p>
        <b-form>
          <base-input
            placeholder="Email Address"
            type="email"
            rules="required"
            name="Email Address"
          />
          <base-input
            placeholder="Repeat Email Adress"
            type="email"
            rules="required"
            name="Repeat Email Adress"
          />
        </b-form>
        <p class="subTitle">
          We will not be able to recover funds send to the wrong adress, please
          make sure the information you enter is correct.
        </p>
        <base-checkbox-group
          name="agreement"
          :options="agreement"
          rules="required"
        ></base-checkbox-group>
        <button type="submit" class="btn btn-primary full">
          Connect my Paypal Account
        </button>
      </b-modal>

      <!-- modal2 -->

      <b-modal
        content-class="accountModal"
        id="bankTransfer"
        ref="bankTransfer"
        no-close-on-backdrop
        centered
      >
        <bank-transfer
          title="Bank Transfer"
          sub-title="We will use Bank Tranfer to send you money when you initiate a
          withdrawal."
          note="We will not be able to recover funds send to the wrong IBAN Acount,
            please make sure the information you enter is correct."
          @submitted="closeModal"
        ></bank-transfer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    data: [String, Number, Object],
  },
  data() {
    return {
      selected: "",
      agreement: [{ text: "I understand and agree." }],
      type: "transactions",
      filterTypes: [
        { value: "withdraws", text: "Withdraws" },
        { value: "transactions", text: "Transactions" },
      ],
      filterDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  components: {
    EarningSummary: () =>
      import(
        /* webpackChunkName: "EarningSummary" */ "@/components/user/earnings/EarningSummary.vue"
      ),
    BillingFilters: () =>
      import(
        /* webpackChunkName: "BillingFilters" */ "@/components/common/billing/BillingFilters.vue"
      ),
    Transactions: () =>
      import(
        /* webpackChunkName: "Trasactions" */ "@/components/common/billing/Transactions.vue"
      ),
    Withdraws: () =>
      import(
        /* webpackChunkName: "Withdraws" */ "@/components/user/earnings/Withdraws.vue"
      ),
    BankTransfer: () =>
      import(
        /* webpackChunkName: "Banktransfer" */ "@/components/user/earnings/BankTransfer.vue"
      ),
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    ...mapActions(["getBalance"]),
    closeModal() {
      this.$refs["bankTransfer"].hide();
    },
    filters(data) {
      this.type = data.type;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.hasMore = true;
      this.infiniteId += 1;
      this.page = 1;
      this.isFirst = true;
      this.filterDate.startDate = data.startDate;
      this.filterDate.endDate = data.endDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.earning {
  h1 {
    font-weight: 700;
    line-height: 1;
  }
  .expectedEarning {
    position: absolute;
    right: 0;
    top: 25px;
    font-size: rem(18px);
    font-weight: 700;
    color: var(--textPrimary);
    margin: 0;
    @media screen and (max-width: 991px) {
      top: 20px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
</style>
