<template>
  <div class="page404">
    <h1>Not Found</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page404 {
  @include flex(center, center);
  height: 100%;
  h1 {
    text-align: center;
  }
}
</style>
