<template>
  <div class="accessDenined">
    <h2>Access Denined</h2>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.accessDenined {
  @include flex(center, center);
  height: 100%;
  h2 {
    text-align: center;
  }
}
</style>
